import { createContext } from 'react';
import { getI18n } from 'lib/i18n/getI18n';

// The properties in `msgBase` are not included in the `alwaysLoadedNs`,
// which means they will not be loaded on all pages by default. If any of these properties
// are required as filters, they should be moved to the `filtersTexts` object.
// Additionally, their corresponding translations should be moved from the 'supplier'
// namespace to the 'filters' namespace to ensure they are loaded properly across all sites.
export const msgBase = {
  packagesMinPricePp: () => getI18n().t('supplier:msg.packagesMinPricePp'),
  photoPrinting: () => getI18n().t('supplier:msg.photoPrinting'),
  kilts: () => getI18n().t('supplier:msg.kilts'),
  audioRetouching: () => getI18n().t('supplier:msg.audioRetouching'),
  miniCooper: () => getI18n().t('supplier:msg.miniCooper'),
  disco: () => getI18n().t('supplier:msg.disco'),
  londonCab: () => getI18n().t('supplier:msg.londonCab'),
  kosher: () => getI18n().t('supplier:msg.kosher'),
  candelabras: () => getI18n().t('supplier:msg.candelabras'),
  nutFree: () => getI18n().t('supplier:msg.nutFree'),
  venueFaqQuestion8: () => getI18n().t('supplier:msg.venueFaqQuestion8'),
  tieredCakes: () => getI18n().t('supplier:msg.tieredCakes'),
  generator: () => getI18n().t('supplier:msg.generator'),
  cakeFaqQuestion7: () => getI18n().t('supplier:msg.cakeFaqQuestion7'),
  camperVans: () => getI18n().t('supplier:msg.camperVans'),
  honeymoonSuite: () => getI18n().t('supplier:msg.honeymoonSuite'),
  heating: () => getI18n().t('supplier:msg.heating'),
  tablesAndChairsProvided: () => getI18n().t('supplier:msg.tablesAndChairsProvided'),
  musicStyleSection: () => getI18n().t('supplier:msg.musicStyleSection'),
  musicStyle: () => getI18n().t('supplier:msg.musicStyle'),
  packagesMinNoOfGuests: () => getI18n().t('supplier:msg.packagesMinNoOfGuests'),
  industryAwards: () => getI18n().t('supplier:msg.industryAwards'),
  dancefloorHire: () => getI18n().t('supplier:msg.dancefloorHire'),
  entertainmentFaqQuestion7: () => getI18n().t('supplier:msg.entertainmentFaqQuestion7'),
  marqueeWeddingSpecialist: () => getI18n().t('supplier:msg.marqueeWeddingSpecialist'),
  programsPlaceCardsMenus: () => getI18n().t('supplier:msg.programsPlaceCardsMenus'),
  nutritionist: () => getI18n().t('supplier:msg.nutritionist'),
  rollsRoyce: () => getI18n().t('supplier:msg.rollsRoyce'),
  transportFaqQuestion9: () => getI18n().t('supplier:msg.transportFaqQuestion9'),
  watches: () => getI18n().t('supplier:msg.watches'),
  engagementShoots: () => getI18n().t('supplier:msg.engagementShoots'),
  sharingStyle: () => getI18n().t('supplier:msg.sharingStyle'),
  stationeryFaqQuestion9: () => getI18n().t('supplier:msg.stationeryFaqQuestion9'),
  reportageDocumentary: () => getI18n().t('supplier:msg.reportageDocumentary'),
  natural: () => getI18n().t('supplier:msg.natural'),
  editorial: () => getI18n().t('supplier:msg.editorial'),
  videographyAvailable: () => getI18n().t('filters:msg.videographyAvailable'),
  stayThroughReception: () => getI18n().t('supplier:msg.stayThroughReception'),
  floristFaqQuestion9: () => getI18n().t('supplier:msg.floristFaqQuestion9'),
  accessories: () => getI18n().t('supplier:msg.accessories'),
  stretchLimos: () => getI18n().t('supplier:msg.stretchLimos'),
  balloons: () => getI18n().t('supplier:msg.balloons'),
  fireworks: () => getI18n().t('supplier:msg.fireworks'),
  astonMartin: () => getI18n().t('supplier:msg.astonMartin'),
  airbrush: () => getI18n().t('supplier:msg.airbrush'),
  bespokeTailoring: () => getI18n().t('supplier:msg.bespokeTailoring'),
  ceremonyMusic: () => getI18n().t('supplier:msg.ceremonyMusic'),
  pageboyAttire: () => getI18n().t('supplier:msg.pageboyAttire'),
  photographyStyleSection: () => getI18n().t('supplier:msg.photographyStyleSection'),
  photographyStyle: () => getI18n().t('supplier:msg.photographyStyle'),
  cityView: () => getI18n().t('supplier:msg.cityView'),
  freeSamples: () => getI18n().t('supplier:msg.freeSamples'),
  genericFaqQuestion5: () => getI18n().t('supplier:msg.genericFaqQuestion5'),
  showroom: () => getI18n().t('supplier:msg.showroom'),
  jaguar: () => getI18n().t('supplier:msg.jaguar'),
  discoLighting: () => getI18n().t('supplier:msg.discoLighting'),
  genericFaqQuestion2: () => getI18n().t('supplier:msg.genericFaqQuestion2'),
  aisleDecorations: () => getI18n().t('supplier:msg.aisleDecorations'),
  bouquets: () => getI18n().t('supplier:msg.bouquets'),
  albumsGuestbooks: () => getI18n().t('supplier:msg.albumsGuestbooks'),
  accreditationsAndAssociations: () => getI18n().t('supplier:msg.accreditationsAndAssociations'),
  peakedHatMarquees: () => getI18n().t('supplier:msg.peakedHatMarquees'),
  venueFaqQuestion10: () => getI18n().t('supplier:msg.venueFaqQuestion10'),
  mobileServices: () => getI18n().t('supplier:msg.mobileServices'),
  clearCeiling: () => getI18n().t('supplier:msg.clearCeiling'),
  waxing: () => getI18n().t('supplier:msg.waxing'),
  setUp: () => getI18n().t('supplier:msg.setUp'),
  hogRoast: () => getI18n().t('supplier:msg.hogRoast'),
  iceSculptures: () => getI18n().t('supplier:msg.iceSculptures'),
  personalTrainer: () => getI18n().t('supplier:msg.personalTrainer'),
  latinSalsa: () => getI18n().t('supplier:msg.latinSalsa'),
  childrenEntertainment: () => getI18n().t('supplier:msg.childrenEntertainment'),
  businessTypeSectionNote: () => getI18n().t('supplier:msg.businessTypeSectionNote'),
  venueFaqQuestion1: (venueName?: string, isLoggedIn?: boolean) =>
    isLoggedIn
      ? getI18n().t('supplier:msg.venueFaqQuestion1')
      : getI18n().t('supplier:msg.venueFaqQuestionWithName1', { venueName }),
  facebook: () => getI18n().t('supplier:msg.facebook'),
  stationeryFaqQuestion7: () => getI18n().t('supplier:msg.stationeryFaqQuestion7'),
  groupBookings: () => getI18n().t('supplier:msg.groupBookings'),
  magicians: () => getI18n().t('supplier:msg.magicians'),
  flowerGirlDresses: () => getI18n().t('supplier:msg.flowerGirlDresses'),
  facePaint: () => getI18n().t('supplier:msg.facePaint'),
  dressDesigner: () => getI18n().t('supplier:msg.dressDesigner'),
  fittings: () => getI18n().t('supplier:msg.fittings'),
  jewelleryFaqQuestion7: () => getI18n().t('supplier:msg.jewelleryFaqQuestion7'),
  preWeddingTrial: () => getI18n().t('supplier:msg.preWeddingTrial'),
  menswearFaqQuestion8: () => getI18n().t('supplier:msg.menswearFaqQuestion8'),
  photoFaqQuestion8: () => getI18n().t('supplier:msg.photoFaqQuestion8'),
  sameDayHighlightFilm: () => getI18n().t('supplier:msg.sameDayHighlightFilm'),
  treatsAndFavours: () => getI18n().t('supplier:msg.treatsAndFavours'),
  shoes: () => getI18n().t('supplier:msg.shoes'),
  morningSuits: () => getI18n().t('supplier:msg.morningSuits'),
  motorbikeSideCar: () => getI18n().t('supplier:msg.motorbikeSideCar'),
  venueFaqQuestion9: (venueName?: string, isLoggedIn?: boolean) =>
    isLoggedIn
      ? getI18n().t('supplier:msg.venueFaqQuestion9')
      : getI18n().t('supplier:msg.venueFaqQuestionWithName9', { venueName }),
  foodTypeSection: () => getI18n().t('supplier:msg.foodTypeSection'),
  foodType: () => getI18n().t('supplier:msg.foodType'),
  dj: () => getI18n().t('supplier:msg.dj'),
  googlePlus: () => getI18n().t('supplier:msg.googlePlus'),
  laserHairRemoval: () => getI18n().t('supplier:msg.laserHairRemoval'),
  highEndRetailer: () => getI18n().t('supplier:msg.highEndRetailer'),
  beautyFaqQuestion8: () => getI18n().t('supplier:msg.beautyFaqQuestion8'),
  chuppah: () => getI18n().t('supplier:msg.chuppah'),
  tiaras: () => getI18n().t('supplier:msg.tiaras'),
  backUpEquipment: () => getI18n().t('supplier:msg.backUpEquipment'),
  floristFaqQuestion8: () => getI18n().t('supplier:msg.floristFaqQuestion8'),
  caricaturists: () => getI18n().t('supplier:msg.caricaturists'),
  ferrari: () => getI18n().t('supplier:msg.ferrari'),
  maleVocals: () => getI18n().t('supplier:msg.maleVocals'),
  weddingInvitations: () => getI18n().t('supplier:msg.weddingInvitations'),
  flexibleHoursOnTheDay: () => getI18n().t('supplier:msg.flexibleHoursOnTheDay'),
  genericFaqQuestion4: () => getI18n().t('supplier:msg.genericFaqQuestion4'),
  weddingRings: () => getI18n().t('supplier:msg.weddingRings'),
  asianCuisine: () => getI18n().t('supplier:msg.asianCuisine'),
  catererFaqQuestion9: () => getI18n().t('supplier:msg.catererFaqQuestion9'),
  gradeListedBuilding: () => getI18n().t('supplier:msg.gradeListedBuilding'),
  silkFlowers: () => getI18n().t('supplier:msg.silkFlowers'),
  chairs: () => getI18n().t('supplier:msg.chairs'),
  jewellery: () => getI18n().t('supplier:msg.jewellery'),
  entertainmentFaqQuestion9: () => getI18n().t('supplier:msg.entertainmentFaqQuestion9'),
  videoFaqQuestion9: () => getI18n().t('supplier:msg.videoFaqQuestion9'),
  stayThroughCeremony: () => getI18n().t('supplier:msg.stayThroughCeremony'),
  makeUpArtists: () => getI18n().t('supplier:msg.makeUpArtists'),
  seatedMeal: () => getI18n().t('supplier:msg.seatedMeal'),
  catererFaqQuestion8: () => getI18n().t('supplier:msg.catererFaqQuestion8'),
  completeFootageOnDvdUsb: () => getI18n().t('supplier:msg.completeFootageOnDvdUsb'),
  americanClassic: () => getI18n().t('supplier:msg.americanClassic'),
  'typeDetails.venue.time.extension': () =>
    getI18n().t('supplier:msg.typeDetails.venue.time.extension'),
  'asian-weddings': () => getI18n().t('supplier:msg.asianWeddings'),
  'jewish-weddings': () => getI18n().t('supplier:msg.jewishWeddings'),
  'muslim-weddings': () => getI18n().t('supplier:msg.muslimWeddings'),
  'other-weddings': () => getI18n().t('supplier:msg.otherWeddings'),
  jewelleryFaqQuestion10: () => getI18n().t('supplier:msg.jewelleryFaqQuestion10'),
  vocalCoach: () => getI18n().t('supplier:msg.vocalCoach'),
  veils: () => getI18n().t('supplier:msg.veils'),
  minimumOrder: () => getI18n().t('supplier:msg.minimumOrder'),
  imagesOnDvd: () => getI18n().t('supplier:msg.imagesOnDvd'),
  portaloos: () => getI18n().t('supplier:msg.portaloos'),
  greeneryHire: () => getI18n().t('supplier:msg.greeneryHire'),
  dressFaqQuestion8: () => getI18n().t('supplier:msg.dressFaqQuestion8'),
  weddingCake: () => getI18n().t('supplier:msg.weddingCake'),
  guitar: () => getI18n().t('supplier:msg.guitar'),
  daimler: () => getI18n().t('supplier:msg.daimler'),
  plannersFaqQuestion7: () => getI18n().t('supplier:msg.plannersFaqQuestion7'),
  dressHire: () => getI18n().t('supplier:msg.dressHire'),
  beautyFaqQuestion7: () => getI18n().t('supplier:msg.beautyFaqQuestion7'),
  alcoholProvision: () => getI18n().t('supplier:msg.alcoholProvision'),
  tables: () => getI18n().t('supplier:msg.tables'),
  marqueeCatering: () => getI18n().t('supplier:msg.marqueeCatering'),
  sportsCars: () => getI18n().t('supplier:msg.sportsCars'),
  outdoorCeremonyLicence: () => getI18n().t('supplier:msg.outdoorCeremonyLicence'),
  catererFaqQuestion10: () => getI18n().t('supplier:msg.catererFaqQuestion10'),
  dressFaqQuestion7: () => getI18n().t('supplier:msg.dressFaqQuestion7'),
  lighting: () => getI18n().t('supplier:msg.lighting'),
  canapes: () => getI18n().t('supplier:msg.canapes'),
  loungeFurnitureHire: () => getI18n().t('supplier:msg.loungeFurnitureHire'),
  jewelleryFaqQuestion8: () => getI18n().t('supplier:msg.jewelleryFaqQuestion8'),
  ensembleGroup: () => getI18n().t('supplier:msg.ensembleGroup'),
  fullServicePlanning: () => getI18n().t('supplier:msg.fullServicePlanning'),
  carpet: () => getI18n().t('supplier:msg.carpet'),
  noOfAdditionalGuestRooms: () => getI18n().t('supplier:msg.noOfAdditionalGuestRooms'),
  'typeDetails.venue.accommodation.rooms': () =>
    getI18n().t('supplier:msg.typeDetails.venue.accommodation.rooms'),
  flooring: () => getI18n().t('supplier:msg.flooring'),
  doves: () => getI18n().t('supplier:msg.doves'),
  horseAndCarriage: () => getI18n().t('supplier:msg.horseAndCarriage'),
  loungeSuits: () => getI18n().t('supplier:msg.loungeSuits'),
  churchBells: () => getI18n().t('supplier:msg.churchBells'),
  decorationFaqQuestion9: () => getI18n().t('supplier:msg.decorationFaqQuestion9'),
  massage: () => getI18n().t('supplier:msg.massage'),
  printing: () => getI18n().t('supplier:msg.printing'),
  cakeFaqQuestion10: () => getI18n().t('supplier:msg.cakeFaqQuestion10'),
  gold: () => getI18n().t('supplier:msg.gold'),
  chauffeurService: () => getI18n().t('supplier:msg.chauffeurService'),
  pricingSection: () => getI18n().t('supplier:msg.pricingSection'),
  bridalChangingFacilities: () => getI18n().t('supplier:msg.bridalChangingFacilities'),
  availableForPhotoShoot: () => getI18n().t('supplier:msg.availableForPhotoShoot'),
  ceremonyReceptionSection: () => getI18n().t('supplier:msg.ceremonyReceptionSection'),
  ceremonyReception: () => getI18n().t('supplier:msg.ceremonyReception'),
  diyInvitations: () => getI18n().t('supplier:msg.diyInvitations'),
  popRock: () => getI18n().t('supplier:msg.popRock'),
  cufflinks: () => getI18n().t('supplier:msg.cufflinks'),
  linenProvided: () => getI18n().t('supplier:msg.linenProvided'),
  engagementRings: () => getI18n().t('supplier:msg.engagementRings'),
  decorationFaqQuestion8: () => getI18n().t('supplier:msg.decorationFaqQuestion8'),
  venueFaqQuestion3: () => getI18n().t('supplier:msg.venueFaqQuestion3'),
  brass: () => getI18n().t('supplier:msg.brass'),
  engraving: () => getI18n().t('supplier:msg.engraving'),
  decorativeHireProps: () => getI18n().t('supplier:msg.decorativeHireProps'),
  ceilidhIrishTraditional: () => getI18n().t('supplier:msg.ceilidhIrishTraditional'),
  stationeryFaqQuestion10: () => getI18n().t('supplier:msg.stationeryFaqQuestion10'),
  beautyFaqQuestion10: () => getI18n().t('supplier:msg.beautyFaqQuestion10'),
  dietaryOptionsSection: () => getI18n().t('supplier:msg.dietaryOptionsSection'),
  dietaryOptions: () => getI18n().t('supplier:msg.dietaryOptions'),
  rapBeatbox: () => getI18n().t('supplier:msg.rapBeatbox'),
  genericFaqQuestion3: () => getI18n().t('supplier:msg.genericFaqQuestion3'),
  drinksCocktailsMusic: () => getI18n().t('supplier:msg.drinksCocktailsMusic'),
  barHire: () => getI18n().t('supplier:msg.barHire'),
  tanning: () => getI18n().t('supplier:msg.tanning'),
  secondShooterAvailable: () => getI18n().t('supplier:msg.secondShooterAvailable'),
  photoBoothAvailable: () => getI18n().t('supplier:msg.photoBoothAvailable'),
  bouquetPreservation: () => getI18n().t('supplier:msg.bouquetPreservation'),
  soloMusician: () => getI18n().t('supplier:msg.soloMusician'),
  featuredIn: () => getI18n().t('supplier:msg.featuredIn'),
  jewelleryFaqQuestion9: () => getI18n().t('supplier:msg.jewelleryFaqQuestion9'),
  modernLuxuryCars: () => getI18n().t('supplier:msg.modernLuxuryCars'),
  transportFaqQuestion8: () => getI18n().t('supplier:msg.transportFaqQuestion8'),
  onTheDayWeddingCoordination: () => getI18n().t('supplier:msg.onTheDayWeddingCoordination'),
  toastMasterOfCeremony: () => getI18n().t('supplier:msg.toastMasterOfCeremony'),
  flowerGirlBaskets: () => getI18n().t('supplier:msg.flowerGirlBaskets'),
  usualStartTime: () => getI18n().t('supplier:msg.usualStartTime'),
  'typeDetails.venue.time.usualStart': () =>
    getI18n().t('supplier:msg.typeDetails.venue.time.usualStart'),
  saveTheDatesThankYouNotes: () => getI18n().t('supplier:msg.saveTheDatesThankYouNotes'),
  accommodationNoOfPeople: () => getI18n().t('supplier:msg.accommodationNoOfPeople'),
  'typeDetails.venue.accommodation.people': () =>
    getI18n().t('supplier:msg.typeDetails.venue.accommodation.people'),
  linkedin: () => getI18n().t('supplier:msg.linkedin'),
  candles: () => getI18n().t('supplier:msg.candles'),
  candyCarts: () => getI18n().t('supplier:msg.candyCarts'),
  venueSection: () => getI18n().t('supplier:msg.venueSection'),
  details: () => getI18n().t('supplier:msg.details'),
  stayThroughDancing: () => getI18n().t('supplier:msg.stayThroughDancing'),
  agency: () => getI18n().t('supplier:msg.agency'),
  corsages: () => getI18n().t('supplier:msg.corsages'),
  blackTie: () => getI18n().t('supplier:msg.blackTie'),
  cleanUp: () => getI18n().t('supplier:msg.cleanUp'),
  inboundDestinationWeddingSpecialist: () =>
    getI18n().t('supplier:msg.inboundDestinationWeddingSpecialist'),
  soulMotownRAndB: () => getI18n().t('supplier:msg.soulMotownRAndB'),
  waistcoats: () => getI18n().t('supplier:msg.waistcoats'),
  preWeddingConsultation: () => getI18n().t('supplier:msg.preWeddingConsultation'),
  barCocktailService: () => getI18n().t('supplier:msg.barCocktailService'),
  firstLookPhotographer: () => getI18n().t('supplier:msg.firstLookPhotographer'),
  lamborghini: () => getI18n().t('supplier:msg.lamborghini'),
  swingJive: () => getI18n().t('supplier:msg.swingJive'),
  lawnGames: () => getI18n().t('supplier:msg.lawnGames'),
  steadicam: () => getI18n().t('supplier:msg.steadicam'),
  chocolateFountains: () => getI18n().t('supplier:msg.chocolateFountains'),
  confetti: () => getI18n().t('supplier:msg.confetti'),
  stationeryFaqQuestion8: () => getI18n().t('supplier:msg.stationeryFaqQuestion8'),
  weekOfWeddingPlanning: () => getI18n().t('supplier:msg.weekOfWeddingPlanning'),
  signs: () => getI18n().t('supplier:msg.signs'),
  marqueeFaqQuestion7: () => getI18n().t('supplier:msg.marqueeFaqQuestion7'),
  tablewareProvided: () => getI18n().t('supplier:msg.tablewareProvided'),
  venueFaqQuestion4: (venueName?: string, isLoggedIn?: boolean) =>
    isLoggedIn
      ? getI18n().t('supplier:msg.venueFaqQuestion4')
      : getI18n().t('supplier:msg.venueFaqQuestionWithName4', {
          venueName,
        }),
  tastings: () => getI18n().t('supplier:msg.tastings'),
  imagesOnUsb: () => getI18n().t('supplier:msg.imagesOnUsb'),
  photoFaqQuestion9: () => getI18n().t('supplier:msg.photoFaqQuestion9'),
  bunting: () => getI18n().t('supplier:msg.bunting'),
  buttonholes: () => getI18n().t('supplier:msg.buttonholes'),
  jazzBand: () => getI18n().t('supplier:msg.jazzBand'),
  onTheDayServices: () => getI18n().t('supplier:msg.onTheDayServices'),
  tattooCoverUpMakeUp: () => getI18n().t('supplier:msg.tattooCoverUpMakeUp'),
  personalTrainers: () => getI18n().t('supplier:msg.personalTrainers'),
  organ: () => getI18n().t('supplier:msg.organ'),
  videoFaqQuestion7: () => getI18n().t('supplier:msg.videoFaqQuestion7'),
  jazz: () => getI18n().t('supplier:msg.jazz'),
  chairCovers: () => getI18n().t('supplier:msg.chairCovers'),
  midnightTreats: () => getI18n().t('supplier:msg.midnightTreats'),
  delivery: () => getI18n().t('supplier:msg.delivery'),
  venueType: () => getI18n().t('supplier:msg.venueType'),
  type: () => getI18n().t('supplier:msg.type'),
  'typeDetails.venue.type': () => getI18n().t('supplier:msg.typeDetails.venue.type'),
  'typeDetails.venue': () => getI18n().t('supplier:msg.typeDetails.venue'),
  venueTypeFilter: () => getI18n().t('supplier:msg.venueTypeFilter'),
  venueStyleFilter: () => getI18n().t('supplier:msg.venueStyleFilter'),
  genericFaqQuestion6: () => getI18n().t('supplier:msg.genericFaqQuestion6'),
  driedFlowers: () => getI18n().t('supplier:msg.driedFlowers'),
  bridalBoutique: () => getI18n().t('supplier:msg.bridalBoutique'),
  marqueeFaqQuestion8: () => getI18n().t('supplier:msg.marqueeFaqQuestion8'),
  casinos: () => getI18n().t('supplier:msg.casinos'),
  functionBand: () => getI18n().t('supplier:msg.functionBand'),
  hairdressing: () => getI18n().t('supplier:msg.hairdressing'),
  discoBall: () => getI18n().t('supplier:msg.discoBall'),
  diningFurnitureHire: () => getI18n().t('supplier:msg.diningFurnitureHire'),
  generalServicesSection: () => getI18n().t('supplier:msg.generalServicesSection'),
  generalServices: () => getI18n().t('supplier:msg.generalServices'),
  funk70SDisco: () => getI18n().t('supplier:msg.funk70SDisco'),
  lastMinuteOrders: () => getI18n().t('supplier:msg.lastMinuteOrders'),
  microphones: () => getI18n().t('supplier:msg.microphones'),
  beautyFaqQuestion9: () => getI18n().t('supplier:msg.beautyFaqQuestion9'),
  plannersFaqQuestion9: () => getI18n().t('supplier:msg.plannersFaqQuestion9'),
  weddingAlbums: () => getI18n().t('supplier:msg.weddingAlbums'),
  laserCutStationery: () => getI18n().t('supplier:msg.laserCutStationery'),
  engravedStationery: () => getI18n().t('supplier:msg.engravedStationery'),
  vintageClassicCars: () => getI18n().t('supplier:msg.vintageClassicCars'),
  silver: () => getI18n().t('supplier:msg.silver'),
  digitalEditing: () => getI18n().t('supplier:msg.digitalEditing'),
  traditionalCanvasPoleMarquees: () => getI18n().t('supplier:msg.traditionalCanvasPoleMarquees'),
  receptionMusic: () => getI18n().t('supplier:msg.receptionMusic'),
  dolliesAndGliders: () => getI18n().t('supplier:msg.dolliesAndGliders'),
  bridalPrepShots: () => getI18n().t('supplier:msg.bridalPrepShots'),
  threading: () => getI18n().t('supplier:msg.threading'),
  videoFaqQuestion10: () => getI18n().t('supplier:msg.videoFaqQuestion10'),
  celebrantOfficiant: () => getI18n().t('supplier:msg.celebrantOfficiant'),
  arcadeGameHire: () => getI18n().t('supplier:msg.arcadeGameHire'),
  instrumentsSection: () => getI18n().t('supplier:msg.instrumentsSection'),
  instruments: () => getI18n().t('supplier:msg.instruments'),
  photoFaqQuestion7: () => getI18n().t('supplier:msg.photoFaqQuestion7'),
  otherInstruments: () => getI18n().t('supplier:msg.otherInstruments'),
  completeStylingService: () => getI18n().t('supplier:msg.completeStylingService'),
  dancers: () => getI18n().t('supplier:msg.dancers'),
  chineseJapaneseCuisine: () => getI18n().t('supplier:msg.chineseJapaneseCuisine'),
  corkageCharge: () => getI18n().t('supplier:msg.corkageCharge'),
  entertainmentFaqQuestion10: () => getI18n().t('supplier:msg.entertainmentFaqQuestion10'),
  toastmasterMasterOfCeremony: () => getI18n().t('supplier:msg.toastmasterMasterOfCeremony'),
  violin: () => getI18n().t('supplier:msg.violin'),
  twitter: () => getI18n().t('supplier:msg.twitter'),
  highStreetRetailer: () => getI18n().t('supplier:msg.highStreetRetailer'),
  bags: () => getI18n().t('supplier:msg.bags'),
  venueHireOnlyMinPrice: () => getI18n().t('supplier:msg.venueHireOnlyMinPrice'),
  bespokeDesign: () => getI18n().t('supplier:msg.bespokeDesign'),
  transportFaqQuestion10: () => getI18n().t('supplier:msg.transportFaqQuestion10'),
  dressFaqQuestion9: () => getI18n().t('supplier:msg.dressFaqQuestion9'),
  mobileBarServices: () => getI18n().t('supplier:msg.mobileBarServices'),
  takeRequests: () => getI18n().t('supplier:msg.takeRequests'),
  venueFaqQuestion5: (venueName?: string, isLoggedIn?: boolean) =>
    isLoggedIn
      ? getI18n().t('supplier:msg.venueFaqQuestion5')
      : getI18n().t('supplier:msg.venueFaqQuestionWithName5', {
          venueName,
        }),
  giantLetters: () => getI18n().t('supplier:msg.giantLetters'),
  artistic: () => getI18n().t('supplier:msg.artistic'),
  musicalOverlay: () => getI18n().t('supplier:msg.musicalOverlay'),
  musicFaqQuestion7: () => getI18n().t('supplier:msg.musicFaqQuestion7'),
  learnNewSongs: () => getI18n().t('supplier:msg.learnNewSongs'),
  changingOnly: () => getI18n().t('supplier:msg.changingOnly'),
  teethWhitening: () => getI18n().t('supplier:msg.teethWhitening'),
  appointmentOnly: () => getI18n().t('supplier:msg.appointmentOnly'),
  photoAndVideoBooth: () => getI18n().t('supplier:msg.photoAndVideoBooth'),
  caribbeanCuisine: () => getI18n().t('supplier:msg.caribbeanCuisine'),
  tipisWigwams: () => getI18n().t('supplier:msg.tipisWigwams'),
  staffProvided: () => getI18n().t('supplier:msg.staffProvided'),
  frameClearspanMarquees: () => getI18n().t('supplier:msg.frameClearspanMarquees'),
  plannersFaqQuestion8: () => getI18n().t('supplier:msg.plannersFaqQuestion8'),
  genericFaqQuestion1: () => getI18n().t('supplier:msg.genericFaqQuestion1'),
  preWeddingVenueVisit: () => getI18n().t('supplier:msg.preWeddingVenueVisit'),
  indoorReceptionNoOfPeople: () => getI18n().t('supplier:msg.indoorReceptionNoOfPeople'),
  'typeDetails.venue.capacity.reception': () =>
    getI18n().t('supplier:msg.typeDetails.venue.capacity.reception'),
  drums: () => getI18n().t('supplier:msg.drums'),
  piano: () => getI18n().t('supplier:msg.piano'),
  bartending: () => getI18n().t('supplier:msg.bartending'),
  floristFaqQuestion7: () => getI18n().t('supplier:msg.floristFaqQuestion7'),
  suitHire: () => getI18n().t('supplier:msg.suitHire'),
  cakeFaqQuestion8: () => getI18n().t('supplier:msg.cakeFaqQuestion8'),
  gifts: () => getI18n().t('supplier:msg.gifts'),
  otherStyleOfMusic: () => getI18n().t('supplier:msg.otherStyleOfMusic'),
  noiseLimiterExperience: () => getI18n().t('supplier:msg.noiseLimiterExperience'),
  outdoorSpace: () => getI18n().t('supplier:msg.outdoorSpace'),
  sheetCakes: () => getI18n().t('supplier:msg.sheetCakes'),
  buffetStyle: () => getI18n().t('supplier:msg.buffetStyle'),
  marqueeFaqQuestion9: () => getI18n().t('supplier:msg.marqueeFaqQuestion9'),
  completeBand: () => getI18n().t('supplier:msg.completeBand'),
  preferredSuppliers: () => getI18n().t('supplier:msg.preferredSuppliers'),
  selfDriveAllowed: () => getI18n().t('supplier:msg.selfDriveAllowed'),
  busAndCoachHire: () => getI18n().t('supplier:msg.busAndCoachHire'),
  starlightLining: () => getI18n().t('supplier:msg.starlightLining'),
  minimumGuestNumbers: () => getI18n().t('supplier:msg.minimumGuestNumbers'),
  hairExtensions: () => getI18n().t('supplier:msg.hairExtensions'),
  recycledPaperOptions: () => getI18n().t('supplier:msg.recycledPaperOptions'),
  dressFaqQuestion10: () => getI18n().t('supplier:msg.dressFaqQuestion10'),
  broaches: () => getI18n().t('supplier:msg.broaches'),
  instrumentsSectionNote: () => getI18n().t('supplier:msg.instrumentsSectionNote'),
  weddingDresses: () => getI18n().t('supplier:msg.weddingDresses'),
  flowerConfetti: () => getI18n().t('supplier:msg.flowerConfetti'),
  vimeo: () => getI18n().t('supplier:msg.vimeo'),
  lining: () => getI18n().t('supplier:msg.lining'),
  speechWriter: () => getI18n().t('supplier:msg.speechWriter'),
  soundEquipment: () => getI18n().t('supplier:msg.soundEquipment'),
  venueHireOnlyMaxPrice: () => getI18n().t('supplier:msg.venueHireOnlyMaxPrice'),
  musicFaqQuestion10: () => getI18n().t('supplier:msg.musicFaqQuestion10'),
  dairyFree: () => getI18n().t('supplier:msg.dairyFree'),
  productsServicesSection: () => getI18n().t('supplier:msg.productsServicesSection'),
  productsServices: () => getI18n().t('supplier:msg.productsServices'),
  paSystem: () => getI18n().t('supplier:msg.paSystem'),
  longEditMoreThan1Hour: () => getI18n().t('supplier:msg.longEditMoreThan1Hour'),
  marryoke: () => getI18n().t('supplier:msg.marryoke'),
  alterations: () => getI18n().t('supplier:msg.alterations'),
  tablePlan: () => getI18n().t('supplier:msg.tablePlan'),
  platinum: () => getI18n().t('supplier:msg.platinum'),
  acoustic: () => getI18n().t('supplier:msg.acoustic'),
  femaleVocals: () => getI18n().t('supplier:msg.femaleVocals'),
  bespokeDesigns: () => getI18n().t('supplier:msg.bespokeDesigns'),
  classical: () => getI18n().t('supplier:msg.classical'),
  doubleDeckerBus: () => getI18n().t('supplier:msg.doubleDeckerBus'),
  spaOnsite: () => getI18n().t('supplier:msg.spaOnsite'),
  specialistWeddingSection: () => getI18n().t('supplier:msg.specialistWeddingSection'),
  tags: () => getI18n().t('supplier:msg.tags'),
  bespokeDressmaker: () => getI18n().t('supplier:msg.bespokeDressmaker'),
  physicalKitchenPremises: () => getI18n().t('supplier:msg.physicalKitchenPremises'),
  luxuryToilets: () => getI18n().t('supplier:msg.luxuryToilets'),
  foodAndDrinkSection: () => getI18n().t('supplier:msg.foodAndDrinkSection'),
  smokeMachine: () => getI18n().t('supplier:msg.smokeMachine'),
  venueFaqQuestion6: (venueName?: string, isLoggedIn?: boolean) =>
    isLoggedIn
      ? getI18n().t('supplier:msg.venueFaqQuestion6')
      : getI18n().t('supplier:msg.venueFaqQuestionWithName6', { venueName }),
  instagram: () => getI18n().t('supplier:msg.instagram'),
  lingerie: () => getI18n().t('supplier:msg.lingerie'),
  harp: () => getI18n().t('supplier:msg.harp'),
  additionalServicesSection: () => getI18n().t('supplier:msg.additionalServicesSection'),
  personalOnlineGallery: () => getI18n().t('supplier:msg.personalOnlineGallery'),
  bentley: () => getI18n().t('supplier:msg.bentley'),
  flowerCrowns: () => getI18n().t('supplier:msg.flowerCrowns'),
  middleEasternCuisine: () => getI18n().t('supplier:msg.middleEasternCuisine'),
  choir: () => getI18n().t('supplier:msg.choir'),
  photoFaqQuestion10: () => getI18n().t('supplier:msg.photoFaqQuestion10'),
  latinSouthAmericanCuisine: () => getI18n().t('supplier:msg.latinSouthAmericanCuisine'),
  dancefloor: () => getI18n().t('supplier:msg.dancefloor'),
  soundPaHire: () => getI18n().t('supplier:msg.soundPaHire'),
  letterpressStationery: () => getI18n().t('supplier:msg.letterpressStationery'),
  inHouseWineList: () => getI18n().t('supplier:msg.inHouseWineList'),
  bridalSuite: () => getI18n().t('supplier:msg.bridalSuite'),
  'typeDetails.venue.accommodation.bridalSuite': () =>
    getI18n().t('supplier:msg.typeDetails.venue.accommodation.bridalSuite'),
  bridesmaidDresses: () => getI18n().t('supplier:msg.bridesmaidDresses'),
  musicFaqQuestion8: () => getI18n().t('supplier:msg.musicFaqQuestion8'),
  catererFaqQuestion7: () => getI18n().t('supplier:msg.catererFaqQuestion7'),
  handCalligraphy: () => getI18n().t('supplier:msg.handCalligraphy'),
  strings: () => getI18n().t('supplier:msg.strings'),
  offSitePreparation: () => getI18n().t('supplier:msg.offSitePreparation'),
  centerpieces: () => getI18n().t('supplier:msg.centerpieces'),
  videoFaqQuestion8: () => getI18n().t('supplier:msg.videoFaqQuestion8'),
  ties: () => getI18n().t('supplier:msg.ties'),
  menswearFaqQuestion9: () => getI18n().t('supplier:msg.menswearFaqQuestion9'),
  venueFaqQuestion7: () => getI18n().t('supplier:msg.venueFaqQuestion7'),
  silverService: () => getI18n().t('supplier:msg.silverService'),
  bouncyCastleHire: () => getI18n().t('supplier:msg.bouncyCastleHire'),
  decorationFaqQuestion10: () => getI18n().t('supplier:msg.decorationFaqQuestion10'),
  livePainting: () => getI18n().t('supplier:msg.livePainting'),
  glutenFree: () => getI18n().t('supplier:msg.glutenFree'),
  plannersFaqQuestion10: () => getI18n().t('supplier:msg.plannersFaqQuestion10'),
  stayThroughSpeeches: () => getI18n().t('supplier:msg.stayThroughSpeeches'),
  cakeFaqQuestion9: () => getI18n().t('supplier:msg.cakeFaqQuestion9'),
  businessTypeSection: () => getI18n().t('supplier:msg.businessTypeSection'),
  businessType: () => getI18n().t('supplier:msg.businessType'),
  cosmeticSurgery: () => getI18n().t('supplier:msg.cosmeticSurgery'),
  winds: () => getI18n().t('supplier:msg.winds'),
  onlineShop: () => getI18n().t('supplier:msg.onlineShop'),
  freshFlowers: () => getI18n().t('supplier:msg.freshFlowers'),
  onlineInvitations: () => getI18n().t('supplier:msg.onlineInvitations'),
  capacityDining: () => getI18n().t('supplier:msg.capacityDining'),
  'typeDetails.venue.capacity.dining': () =>
    getI18n().t('supplier:msg.typeDetails.venue.capacity.dining'),
  entertainmentFaqQuestion8: () => getI18n().t('supplier:msg.entertainmentFaqQuestion8'),
  outboundDestinationWeddingSpecialist: () =>
    getI18n().t('supplier:msg.outboundDestinationWeddingSpecialist'),
  shootWithFilm: () => getI18n().t('supplier:msg.shootWithFilm'),
  bagpipes: () => getI18n().t('supplier:msg.bagpipes'),
  soloSinger: () => getI18n().t('supplier:msg.soloSinger'),
  beauford: () => getI18n().t('supplier:msg.beauford'),
  handmade: () => getI18n().t('supplier:msg.handmade'),
  mcAvailable: () => getI18n().t('supplier:msg.mcAvailable'),
  taxis: () => getI18n().t('supplier:msg.taxis'),
  overviewSection: () => getI18n().t('supplier:msg.overviewSection'),
  shortEditMoreThan15Minutes: () => getI18n().t('supplier:msg.shortEditMoreThan15Minutes'),
  cakeAccessories: () => getI18n().t('supplier:msg.cakeAccessories'),
  bridalSuiteNone: () => getI18n().t('supplier:msg.bridalSuiteNone'),
  traditionalClassic: () => getI18n().t('supplier:msg.traditionalClassic'),
  largeGroupShots: () => getI18n().t('supplier:msg.largeGroupShots'),
  usualFinishTime: () => getI18n().t('supplier:msg.usualFinishTime'),
  'typeDetails.venue.time.usualFinish': () =>
    getI18n().t('supplier:msg.typeDetails.venue.time.usualFinish'),
  pinterest: () => getI18n().t('supplier:msg.pinterest'),
  droneFootage: () => getI18n().t('supplier:msg.droneFootage'),
  projector: () => getI18n().t('supplier:msg.projector'),
  ratPackSwing: () => getI18n().t('supplier:msg.ratPackSwing'),
  weddingArches: () => getI18n().t('supplier:msg.weddingArches'),
  menswearFaqQuestion10: () => getI18n().t('supplier:msg.menswearFaqQuestion10'),
  musicStyleSectionNote: () => getI18n().t('supplier:msg.musicStyleSectionNote'),
  decorationFaqQuestion7: () => getI18n().t('supplier:msg.decorationFaqQuestion7'),
  hatsFascinators: () => getI18n().t('supplier:msg.hatsFascinators'),
  otherWeddingSpecialistPlaceholder: () =>
    getI18n().t('supplier:msg.otherWeddingSpecialistPlaceholder'),
  pocketfoldStationery: () => getI18n().t('supplier:msg.pocketfoldStationery'),
  menswearFaqQuestion7: () => getI18n().t('supplier:msg.menswearFaqQuestion7'),
  catalogueSupplier: () => getI18n().t('supplier:msg.catalogueSupplier'),
  tribute: () => getI18n().t('supplier:msg.tribute'),
  stretchedMarquee: () => getI18n().t('supplier:msg.stretchedMarquee'),
  transportFaqQuestion7: () => getI18n().t('supplier:msg.transportFaqQuestion7'),
  otherFurniture: () => getI18n().t('supplier:msg.otherFurniture'),
  pricesStartFrom: () => getI18n().t('supplier:msg.pricesStartFrom'),
  youtube: () => getI18n().t('supplier:msg.youtube'),
  divideAndRevealCurtain: () => getI18n().t('supplier:msg.divideAndRevealCurtain'),
  marqueeFaqQuestion10: () => getI18n().t('supplier:msg.marqueeFaqQuestion10'),
  hotTubHire: () => getI18n().t('supplier:msg.hotTubHire'),
  helicopter: () => getI18n().t('supplier:msg.helicopter'),
  gemstones: () => getI18n().t('supplier:msg.gemstones'),
  musicFaqQuestion9: () => getI18n().t('supplier:msg.musicFaqQuestion9'),
  karaoke: () => getI18n().t('supplier:msg.karaoke'),
  motherOfTheBrideDresses: () => getI18n().t('supplier:msg.motherOfTheBrideDresses'),
  dramatic: () => getI18n().t('supplier:msg.dramatic'),
  onlineProofing: () => getI18n().t('supplier:msg.onlineProofing'),
  standardRoom: () => getI18n().t('supplier:msg.standardRoom'),
  otherTypeOfPerformer: () => getI18n().t('supplier:msg.otherTypeOfPerformer'),
  dryCleaning: () => getI18n().t('supplier:msg.dryCleaning'),
  danceLessons: () => getI18n().t('supplier:msg.danceLessons'),
  openFlamePermitted: () => getI18n().t('supplier:msg.openFlamePermitted'),
  recommendedSuppliers: () => getI18n().t('supplier:msg.recommendedSuppliers'),
  beautySalons: () => getI18n().t('supplier:msg.beautySalons'),
  floristFaqQuestion10: () => getI18n().t('supplier:msg.floristFaqQuestion10'),
  nailArtists: () => getI18n().t('supplier:msg.nailArtists'),
  physicalShopStudio: () => getI18n().t('supplier:msg.physicalShopStudio'),
  westernEuropeanCuisine: () => getI18n().t('supplier:msg.westernEuropeanCuisine'),
  packagesMaxPricePp: () => getI18n().t('supplier:msg.packagesMaxPricePp'),
  weddingHighlightsFilm: () => getI18n().t('supplier:msg.weddingHighlightsFilm'),
  thankYouCardsPhotoStationery: () => getI18n().t('supplier:msg.thankYouCardsPhotoStationery'),
  blog: () => getI18n().t('supplier:msg.blog'),
  digitalEditingAirbrushing: () => getI18n().t('supplier:msg.digitalEditingAirbrushing'),
  linkedSuppliers: () => getI18n().t('supplier:msg.linkedSuppliers'),
  ourVenue: () => getI18n().t('supplier:msg.ourVenue'),
  halal: () => getI18n().t('supplier:msg.halal'),
  vases: () => getI18n().t('supplier:msg.vases'),
  venueFaqQuestion2: () => getI18n().t('supplier:msg.venueFaqQuestion2'),
  foodVan: () => getI18n().t('supplier:msg.foodVan'),
  serviceStaff: () => getI18n().t('supplier:msg.serviceStaff'),
  decorations: () => getI18n().t('supplier:msg.decorations'),
  ac: () => getI18n().t('supplier:msg.ac'),
  other: () => getI18n().t('supplier:msg.other'),
  childcare: () => getI18n().t('supplier:msg.childcare'),
  stage: () => getI18n().t('supplier:msg.stage'),
  glassware: () => getI18n().t('supplier:msg.glassware'),
  foodDrink: () => getI18n().t('supplier:msg.foodDrink'),
  foodDrinkSection: () => getI18n().t('supplier:msg.foodDrinkSection'),
  yearBusinessWasEstablished: () => getI18n().t('supplier:msg.yearBusinessWasEstablished'),
  noOfWeddingsHosted: () => getI18n().t('supplier:msg.noOfWeddingsHosted'),
  noOfPeopleInTeam: () => getI18n().t('supplier:msg.noOfPeopleInTeam'),
  publicLiabilityInsurance: () => getI18n().t('supplier:msg.publicLiabilityInsurance'),
  'company.established': () => getI18n().t('supplier:msg.company.established'),
  'company.weddingsHosted': () => getI18n().t('supplier:msg.company.weddingsHosted'),
  'company.supplier.weddingsHosted': () =>
    getI18n().t('supplier:msg.company.supplier.weddingsHosted'),
  'company.teamSize': () => getI18n().t('supplier:msg.company.teamSize'),
  'company.insurance': () => getI18n().t('supplier:msg.company.insurance'),
  vegetarian: () => getI18n().t('supplier:msg.vegetarian'),
  overview: () => getI18n().t('supplier:msg.overview'),
  venueFeatures: () => getI18n().t('supplier:msg.venueFeatures'),
  specialOffers: () => getI18n().t('supplier:msg.specialOffers'),
  priceSection: () => getI18n().t('supplier:msg.priceSection'),
  upcomingEvents: () => getI18n().t('supplier:msg.upcomingEvents'),
  pricingAffordable: () => getI18n().t('filters:pricing.pricingCategories.affordable'),
  pricingModerate: () => getI18n().t('filters:pricing.pricingCategories.moderate'),
  pricingLuxury: () => getI18n().t('filters:pricing.pricingCategories.luxury'),
  pricingSuperLuxury: () => getI18n().t('filters:pricing.pricingCategories.superLuxury'),
  floristEcoOptions: () => getI18n().t('supplier:msg.floristEcoOptions'),
  floristEcoOptionsSection: () => getI18n().t('supplier:msg.floristEcoOptionsSection'),
  floristStyles: () => getI18n().t('supplier:msg.floristStyles'),
  floristStylesSection: () => getI18n().t('supplier:msg.floristStylesSection'),
  tablescaping: () => getI18n().t('supplier:msg.tablescaping'),
  bohemian: () => getI18n().t('supplier:msg.bohemian'),
  vintage: () => getI18n().t('supplier:msg.vintage'),
  whimsical: () => getI18n().t('supplier:msg.whimsical'),
  biodegradable: () => getI18n().t('supplier:msg.biodegradable'),
  foamFree: () => getI18n().t('supplier:msg.foamFree'),
  localSourcing: () => getI18n().t('supplier:msg.localSourcing'),
  seasonalSourcing: () => getI18n().t('supplier:msg.seasonalSourcing'),
  toxicChecmicalFree: () => getI18n().t('supplier:msg.toxicChecmicalFree'),
  donuts: () => getI18n().t('supplier:msg.donuts'),
  brownies: () => getI18n().t('supplier:msg.brownies'),
  cheeseBoardPlatter: () => getI18n().t('supplier:msg.cheeseBoardPlatter'),
  cupcakes: () => getI18n().t('supplier:msg.cupcakes'),
  macarons: () => getI18n().t('supplier:msg.macarons'),
  icingOptionsSection: () => getI18n().t('supplier:msg.icingOptionsSection'),
  icingOptions: () => getI18n().t('supplier:msg.icingOptions'),
  buttercream: () => getI18n().t('supplier:msg.buttercream'),
  fondant: () => getI18n().t('supplier:msg.fondant'),
  naked: () => getI18n().t('supplier:msg.naked'),
  semiNaked: () => getI18n().t('supplier:msg.semiNaked'),
  flavourOptionsSection: () => getI18n().t('supplier:msg.flavourOptionsSection'),
  flavourOptions: () => getI18n().t('supplier:msg.flavourOptions'),
  berries: () => getI18n().t('supplier:msg.berries'),
  carrot: () => getI18n().t('supplier:msg.carrot'),
  chocolate: () => getI18n().t('supplier:msg.chocolate'),
  coffee: () => getI18n().t('supplier:msg.coffee'),
  cookiesCream: () => getI18n().t('supplier:msg.cookiesCream'),
  fruitCake: () => getI18n().t('supplier:msg.fruitCake'),
  lemon: () => getI18n().t('supplier:msg.lemon'),
  saltedCaramel: () => getI18n().t('supplier:msg.saltedCaramel'),
  otherFlavour: () => getI18n().t('supplier:msg.otherFlavour'),
  redVelvet: () => getI18n().t('supplier:msg.redVelvet'),
  raspberryWhiteChocolate: () => getI18n().t('supplier:msg.raspberryWhiteChocolate'),
  vanilla: () => getI18n().t('supplier:msg.vanilla'),
  destinationWeddingsSection: () => getI18n().t('supplier:msg.destinationWeddingsSection'),
  indian: () => getI18n().t('supplier:msg.indian'),
  easternEuropean: () => getI18n().t('supplier:msg.easternEuropean'),
  africanCuisine: () => getI18n().t('supplier:msg.africanCuisine'),
  mediterranean: () => getI18n().t('supplier:msg.mediterranean'),
};

// These filters have been moved from msgBase since these can be
// displayed in every page with the search bar, so they have to be always
// loaded.
export const filtersTexts = {
  accommodationRooms: () => getI18n().t('filters:accommodationRooms'),
  affordable: () => getI18n().t('filters:affordable'),
  alcoholLicense: () => getI18n().t('filters:alcoholLicense'),
  alternative: () => getI18n().t('filters:alternative'),
  asian: () => getI18n().t('filters:asian'),
  asianCatering: () => getI18n().t('filters:asianCatering'),
  asianWeddingSpecialist: () => getI18n().t('filters:asianWeddingSpecialist'),
  ballroom: () => getI18n().t('filters:ballroom'),
  bar: () => getI18n().t('filters:bar'),
  barn: () => getI18n().t('filters:barn'),
  beach: () => getI18n().t('filters:beach'),
  bestOfBritain: () => getI18n().t('filters:bestOfBritain'),
  blankCanvas: () => getI18n().t('filters:blankCanvas'),
  byobPermitted: () => getI18n().t('filters:byobPermitted'),
  castle: () => getI18n().t('filters:castle'),
  casual: () => getI18n().t('filters:casual'),
  churchWithinWalkingDistance: () => getI18n().t('filters:churchWithinWalkingDistance'),
  cityHotelCityVenue: () => getI18n().t('filters:cityHotelCityVenue'),
  classic: () => getI18n().t('filters:classic'),
  conferenceCentre: () => getI18n().t('filters:conferenceCentre'),
  confettiPermitted: () => getI18n().t('filters:confettiPermitted'),
  countryHouseManorHouse: () => getI18n().t('filters:countryHouseManorHouse'),
  cruiseBoatYacht: () => getI18n().t('filters:cruiseBoatYacht'),
  disabledAccess: () => getI18n().t('filters:disabledAccess'),
  exclusiveUse: () => getI18n().t('filters:exclusiveUse'),
  externalCateringPermitted: () => getI18n().t('filters:externalCateringPermitted'),
  fireworksPermitted: () => getI18n().t('filters:fireworksPermitted'),
  formal: () => getI18n().t('filters:formal'),
  upTo10: () => getI18n().t('filters:noOfBedroomsFilter.upTo10'),
  rooms10: () => getI18n().t('filters:noOfBedroomsFilter.10'),
  rooms20: () => getI18n().t('filters:noOfBedroomsFilter.20'),
  rooms30: () => getI18n().t('filters:noOfBedroomsFilter.30'),
  rooms50: () => getI18n().t('filters:noOfBedroomsFilter.50'),
  gardenOutdoor: () => getI18n().t('filters:gardenOutdoor'),
  golfCourse: () => getI18n().t('filters:golfCourse'),
  greatViews: () => getI18n().t('filters:greatViews'),
  guests: () => getI18n().t('filters:guests'),
  halalCatering: () => getI18n().t('filters:halalCatering'),
  hasAccommodation: () => getI18n().t('filters:hasAccommodation'),
  hasBridebookOffer: () => getI18n().t('filters:hasBridebookOffer'),
  hasDiscounts: () => getI18n().t('filters:hasDiscounts'),
  hasGift: () => getI18n().t('filters:hasGift'),
  hasLateAvailability: () => getI18n().t('filters:hasLateAvailability'),
  hasQualityPricing: () => getI18n().t('filters:hasQualityPricing'),
  hasWeddingFair: () => getI18n().t('filters:hasWeddingFair'),
  historic: () => getI18n().t('filters:historic'),
  hotel: () => getI18n().t('filters:hotel'),
  inHouseCatering: () => getI18n().t('filters:inHouseCatering'),
  inHouseDancefloor: () => getI18n().t('filters:inHouseDancefloor'),
  inHouseWeddingCoordinator: () => getI18n().t('filters:inHouseWeddingCoordinator'),
  intimate: () => getI18n().t('filters:intimate'),
  isCurated: () => getI18n().t('filters:isCurated'),
  jewishWeddingSpecialist: () => getI18n().t('filters:jewishWeddingSpecialist'),
  kosherCatering: () => getI18n().t('filters:kosherCatering'),
  landscapedGardens: () => getI18n().t('filters:landscapedGardens'),
  lateNightExtensionAvailable: () => getI18n().t('filters:lateNightExtensionAvailable'),
  lovelyGrounds: () => getI18n().t('filters:lovelyGrounds'),
  luxury: () => getI18n().t('filters:luxury'),
  marqueePermitted: () => getI18n().t('filters:marqueePermitted'),
  modern: () => getI18n().t('filters:modern'),
  museumAttraction: () => getI18n().t('filters:museumAttraction'),
  muslimWeddingSpecialist: () => getI18n().t('filters:muslimWeddingSpecialist'),
  onsiteParking: () => getI18n().t('filters:onsiteParking'),
  otherVenueType: () => getI18n().t('filters:otherVenueType'),
  otherWeddingSpecialist: () => getI18n().t('filters:otherWeddingSpecialist'),
  outdoor: () => getI18n().t('filters:outdoor'),
  petFriendly: () => getI18n().t('filters:petFriendly'),
  placeOfWorship: () => getI18n().t('filters:placeOfWorship'),
  pubRestaurant: () => getI18n().t('filters:pubRestaurant'),
  radiusIncrease: () => getI18n().t('filters:radiusIncrease'),
  resort: () => getI18n().t('filters:resort'),
  romantic: () => getI18n().t('filters:romantic'),
  rooftop: () => getI18n().t('filters:rooftop'),
  rustic: () => getI18n().t('filters:rustic'),
  soundSystemAvailable: () => getI18n().t('filters:soundSystemAvailable'),
  sportingVenueStadium: () => getI18n().t('filters:sportingVenueStadium'),
  statelyHome: () => getI18n().t('filters:statelyHome'),
  stylish: () => getI18n().t('filters:stylish'),
  townHallRegistryOffice: () => getI18n().t('filters:townHallRegistryOffice'),
  transportation: () => getI18n().t('filters:transportation'),
  unique: () => getI18n().t('filters:unique'),
  uniqueVenueType: () => getI18n().t('filters:uniqueVenueType'),
  unusual: () => getI18n().t('filters:unusual'),
  urban: () => getI18n().t('filters:urban'),
  vegan: () => getI18n().t('filters:vegan'),
  warehouseFactory: () => getI18n().t('filters:warehouseFactory'),
  waterView: () => getI18n().t('filters:waterView'),
  weddingLicence: () => getI18n().t('filters:weddingLicence'),
  wifi: () => getI18n().t('filters:wifi'),
  winery: () => getI18n().t('filters:winery'),
};

const msg = {
  ...filtersTexts,
  ...msgBase,
};

export type MsgType = typeof msg;
export type MsgKey = keyof MsgType;

type MsgContextContent = { msg: null | MsgType };

export const MsgContext = createContext<MsgContextContent>({ msg: null });

export default msg;
